import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faSearch, faStop, faTimes } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useState } from "react";
import { Formik } from "formik";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { current } from "@reduxjs/toolkit";
import { updateUserDisplaySettings } from "../auth/authActions";

export default function AdminSearchBar({ color, crumb_color, heading, header_color, breadcrumbs }) {
    const [searchValue, setSearchValue] = useState("");
    let history = useHistory();
    const dispatch = useDispatch();

    const { currentUser } = useSelector((state) => state.auth);

    const [displaySettings, setDisplaySettings] = useState(false);

    let headStyle = `w-full mx-auto py-2 ${header_color} `;

    function clearSearch() {
        setSearchValue("");
    }

    async function updateViewValue(UpdateField, updateValue) {
        if (updateValue == true) {
            var valueToUpdate = 1;
        } else {
            var valueToUpdate = 0;
        }

        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}set-view-display`,
            headers: {
                Authorization: `Bearer ${currentUser.token}`,
            },
            data: {
                userId: currentUser.id,
                field: UpdateField,
                value: valueToUpdate,
            },
        });

        dispatch(
            updateUserDisplaySettings({
                [UpdateField]: valueToUpdate,
            })
        );

        let oldValue = window.sessionStorage.getItem("displayUpdated");
        let newValue = new Date();

        const event = new StorageEvent("storage", {
            key: "displayUpdatedDate",
            oldValue,
            newValue,
        });

        window.dispatchEvent(event);
    }

    return (
        <div className={headStyle}>
            {/* <br/> */}

            <Formik
                initialValues={{ searchBox: "" }}
                onSubmit={(values, { setSubmitting }) => {
                    history.push({
                        pathname: "/search-results",
                        state: [searchValue, window.sessionStorage.getItem("categoryForSearch")],
                    });
                }}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="lg:flex lg:items-center lg:justify-center">
                            {/* <p className ={search}>Search</p> */}
                            <div className="relative text-center w-6/12">
                                <div className="absolute top-3 left-16 lg:top-3 lg:left-3">
                                    <FontAwesomeIcon className={"text-gray-400 cursor-pointer text-base"} icon={faSearch} />
                                </div>
                                <div className="absolute top-3 right-5">
                                    <FontAwesomeIcon
                                        className={"text-gray-400 cursor-pointer text-base"}
                                        icon={faTimes}
                                        onClick={() => {
                                            setFieldValue("searchBox", "");
                                            clearSearch();
                                        }}
                                    />
                                </div>
                                <input
                                    type="text"
                                    name="searchBox"
                                    value={values.searchBox}
                                    className="w-full pl-10 pr-20 rounded-lg z-0 focus:shadow focus:outline-none"
                                    onChange={(values) => {
                                        setFieldValue("searchBox", values.target.value);
                                        setSearchValue(values.target.value);
                                    }}
                                    placeholder="Search for products..."
                                />
                            </div>

                            <button
                                type="submit"
                                disabled={isSubmitting}
                                loading={isSubmitting.toString()}
                                className="inline-flex items-center px-12 py-2 ml-32 lg:ml-3 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_red hover:bg-lk_red_hover mt-4 lg:mt-0"
                            >
                                GO &nbsp;{">"}
                            </button>

                            <button
                                id="dropdownDefault"
                                className="px-4 py-2 mr-5 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_grey_one hover:bg-lk_dark_grey_button_hover uppercase ml-5"
                                type="button"
                                onClick={() => {
                                    displaySettings == true ? setDisplaySettings(false) : setDisplaySettings(true);
                                }}
                            >
                                {displaySettings == true ? "Close" : "Display Settings"}
                            </button>

                            {displaySettings == true && (
                                <div id="dropdown" className="w-44 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 px-5 py-5 absolute z-20 right-96 top-40">
                                    <ul className="py-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefault">
                                        <li>
                                            <input
                                                type="checkbox"
                                                value="img"
                                                name="img"
                                                defaultChecked={currentUser?.display_img}
                                                onClick={(value) => {
                                                    updateViewValue("display_img", value.target.checked);
                                                }}
                                            />{" "}
                                            Image &nbsp;&nbsp;
                                        </li>
                                        <li>
                                            <input
                                                type="checkbox"
                                                value="stock"
                                                name="stock"
                                                defaultChecked={currentUser?.display_code}
                                                onClick={(value) => {
                                                    updateViewValue("display_code", value.target.checked);
                                                }}
                                            />{" "}
                                            Stock Code &nbsp;&nbsp;
                                        </li>
                                        <li>
                                            <input
                                                type="checkbox"
                                                value="barCode"
                                                name="barCode"
                                                defaultChecked={currentUser?.display_barCode}
                                                onClick={(value) => {
                                                    updateViewValue("display_barCode", value.target.checked);
                                                }}
                                            />{" "}
                                            Bar Code &nbsp; &nbsp;
                                        </li>
                                        <li>
                                            <input
                                                type="checkbox"
                                                value="desc"
                                                name="desc"
                                                defaultChecked={currentUser?.display_desc}
                                                onClick={(value) => {
                                                    updateViewValue("display_desc", value.target.checked);
                                                }}
                                            />{" "}
                                            Description
                                        </li>
                                    </ul>
                                </div>
                            )}

                            <small>V 1.6.1</small>
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    );
}
