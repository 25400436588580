export function AdminDashboard() {
    return (
        <div className="bg-white">
            <div className="my-8 space-y-6 px-4 sm md:max-w-2xl lg:max-w-4xl xl:max-w-5xl m-auto">
                <h1>Admin</h1>

                <div className="grid grid-cols-4 gap-4">
                    <a
                        className="lkbutton bg-lk_red hover:bg-lk_red_hover w-full border_radius_10 text-center"
                        href="/user-management"
                    >
                        User Management
                    </a>

                    <a
                        className="lkbutton bg-lk_red hover:bg-lk_red_hover w-full border_radius_10 text-center"
                        href="/admin/product-categories"
                    >
                        Product Categories
                    </a>
                </div>
            </div>
        </div>
    );
}
